import pic1 from "../../assets/All-0-4.jpg"
import pic2 from "../../assets/All-0-5.jpg"
import pic3 from "../../assets/D144BCF8-14FB-4597-BC5D-1716469774F2.JPG"
import pic4 from "../../assets/E36-4-0.jpg"
import pic5 from "../../assets/E90-1-0.jpg"
import pic6 from "../../assets/IMG_2374 edit.jpg"
import pic7 from "../../assets/IMG_3138.JPG"
import pic8 from "../../assets/IMG_6156-preview.JPG"
import pic9 from "../../assets/All-0-1.jpg"
import pic10 from "../../assets/E36Crash-1.JPG"

export const SampleImages = [
    pic1,
    pic2,
    pic3,
    pic4,
    pic5,
    pic6,
    pic7,
    pic8,
    pic9,
    pic10
]