import React from "react"
import { Typography, Link } from "@mui/material"
function CoolingSystem() {
  return (
    <div>
      <Typography variant="h4" id="Cooling-System">
        Cooling System
      </Typography>
    </div>
  )
}

export default CoolingSystem
